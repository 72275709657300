import React from 'react'
import { UnderConstruction } from '../../../components';

const PaintingAndDecorating = () => (
  <UnderConstruction />
  // <Layout headerBreakpoint={170}>
  //   <SEO title="Painting And Decorating" />

  //   <Spotlight img={data.roller}/>

  //   <section>
  //     <h1>Painting And Decorating</h1>
  //   </section>

  //   <section>
  //     <div>
  //       <div style={{overflow: 'hidden', margin: '-20px'}}>
  //         <Card image={data.glass}>
  //           <h3>Artexing</h3>
            
  //           <p></p>

  //           <Link to="/services/painting-and-decorating/artexing" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Commercial Decorating Services</h3>
            
  //           <p></p>

  //           <Link to="/services/painting-and-decorating/commercial-decorating-services" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>New Build Decorating</h3>
            
  //           <p></p>

  //           <Link to="/services/painting-and-decorating/new-build-decorating" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Residential Exterior Decorating</h3>
            
  //           <p></p>

  //           <Link to="/services/painting-and-decorating/residential-exterior-decorating" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Residential Interior Decorating</h3>
            
  //           <p></p>

  //           <Link to="/services/painting-and-decorating/residential-interior-decorating" className="button primary">Find Out More</Link>
  //         </Card>

  //         <Card image={data.glass}>
  //           <h3>Wallpapering</h3>
            
  //           <p></p>

  //           <Link to="/services/painting-and-decorating/wallpapering" className="button primary">Find Out More</Link>
  //         </Card>
  //       </div>
  //     </div>
  //   </section>
  // </Layout>
)

export default PaintingAndDecorating
